@value colors: "~styles/colors.module.css";
@value mid-gray from colors;

.accordion {
	background-color: mid-gray;
	border-radius: unset !important;
	box-shadow: unset;
	margin: 8px 0 0 0 !important;
}

.accordion:before {
	display: none;
}

.accordion-summary {
	display: flex;
	justify-content: space-between;
	padding: unset;
	max-height: 32px;
	min-height: 32px !important;
}

.accordion-content {
	margin: 0 !important;
}

.accordion-details {
	display: flex;
	flex-direction: column;
	padding: 5px 0;
}

.project-name {
	align-items: center;
	display: flex;
	margin: unset;
	padding: unset;
	font-family: Barlow;
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: 0.07px;
	color: #a99dff;
}

.abw {
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: normal;
	color: #aaaaaa;
	margin-bottom: 8px;
}

.description {
	font-family: Barlow;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: normal;
	color: #ffffff;
	margin-bottom: 8px;
}

.project-info {
	display: flex;
	flex-direction: column;
}

.fields {
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #ffffff;
	margin-bottom: 1px;
}
