@value colors: "~styles/colors.module.css";
@value colors-1-primary, colors-2-secondary from colors;

.layer {
	display: flex;
	align-items: center;
	position: absolute;
	width: 100%;
	cursor: pointer;
	border-left: 3px solid transparent;
	margin-left: -3px;
	background-clip: content-box;
}

.selected {
	z-index: 500;
}

.hovered {
	border-left-color: colors-2-secondary;
	box-shadow: inset 1px 0 0px -0px colors-2-secondary;
}

.tooltip {
	position: absolute;
	border-radius: 3px;
	background-color: colors-2-secondary;
	z-index: 99999;
}

.tooltip-label {
	display: flex;
	align-items: center;
	padding: 0 4px;
	font-size: 12px;
	white-space: nowrap;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 19px;
	letter-spacing: 0.3px;
	color: #333333;
}

.top-handle,
.bottom-handle {
	display: block;
	position: absolute;
	height: 2px;
	border-radius: 2px;
	left: -8px;
	right: -8px;
	background-color: colors-1-primary;
	z-index: 10;
	padding: 4px;
	background-clip: content-box;
}

.top-handle {
	top: -5px;
}

.bottom-handle {
	bottom: -5px;
}

.position-tooltip {
	position: absolute;
	width: fit-content;
	height: 15px;
	background-color: #644bff;
	font-family: Barlow, sans-serif;
	font-size: 14px;
	font-weight: normal;
	color: #ffffff;
	padding: 0 4px 4px 4px;
	border-radius: 1px;
	user-select: none;
}

.position-tooltip-top {
	top: -18px;
}

.position-tooltip-bottom {
	bottom: -18px;
}
