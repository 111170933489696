@value colors: "~styles/colors.module.css";
@value mid-gray, white from colors;

.top-bottom {
	display: flex;
	flex-direction: column;
	padding-bottom: 24px;
}

.header {
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 31px;
	letter-spacing: 1px;
	color: #aaaaaa;
	margin-top: 16px;
	padding: 0 16px;
}

.fields {
	display: flex;
	justify-content: space-between;
	padding: 0 16px;
}

.text-field {
	display: flex;
	justify-content: center;
}

.text-field:last-child {
	margin-left: 10px;
}
