@value colors: "~styles/colors.module.css";
@value slightly-less-dark-gray from colors;

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-left: 20px;
}

.header-container {
	height: 17px;
}

.header {
	font-size: 14px;
	line-height: 1.43;
	color: slightly-less-dark-gray;
	right: 0;
	margin-right: 10px;
}

.purple-bar {
	position: absolute;
	z-index: 1;
	background-color: rgba(100, 75, 255, 0.23);
	pointer-events: none;
}
