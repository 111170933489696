.root {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.interpretation {
	display: flex;
	height: 100%;
}

.item-text {
	padding-left: 8px;
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
}

.profile-icons {
	width: 24px;
	height: 24px;
}

.profile-button {
	margin-left: 30px !important;
}
