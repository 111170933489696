@value colors: "~styles/colors.module.css";
@value colors-blue-grey-900 from colors;

.library {
	list-style: none;
	padding: 0;
	padding: 0 8px;
  margin: 0 -8px 5px;
}

.root {
	height: 100%;
	margin-top: 16px;
}

.scrollable {
	height: calc(100% - 61px);
	padding: 0 16px;
	overflow-y: auto;
}

.soil-material {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 2px;
  position: relative;
  margin: 0 -8px;
	cursor: pointer;
}

.option-image {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 2px;
}

.soil-material:hover {
	background-color: colors-blue-grey-900;
}

.color-box {
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 2px;
	margin-right: 8px;
	border: 1px solid #5d5f61;
}

.group-label {
	text-transform: uppercase;
	color: white;
	font-family: Barlow, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 2.58;
	letter-spacing: 1px;
	margin-bottom: 0;
}

.group-label:first-child {
	margin-top: 5px;
}

.search-container {
	display: flex;
	align-items: center;
	padding: 0 16px;
	margin-bottom: 10px;
}

.search-container > div {
	flex: 1 1;
}

.search-root {
	padding-left: 8px;
	background-color: #464749;
	margin-right: 10px;
	flex: 1 1;
}

.search-root input {
	padding-left: 0 !important;
}

.search-adornment > svg {
	width: 24px;
	height: 24px;
}

button {
	margin-left: 0 !important;
}

.add-icon {
	font-size: 24px;
}
