.data {
	margin: 0;
	margin-bottom: 4px;
}

.head {
	display: inline-block;
	width: 200px;
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 28px;
	letter-spacing: normal;
}

.value {
	margin-left: 12px;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: normal;
}

.warning {
	color: rgb(240, 204, 138);
}
