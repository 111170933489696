.root {
	display: flex;
	flex-direction: column;
	padding: 10px 10px 0 10px;
	align-self: center;
	margin-left: 35px;
	margin-right: 5px;
	background-color: #78797b;
}

.header {
	margin-left: 30px;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.graphs {
	display: flex;
	flex-direction: row;
	padding: 5px 30px 20px 30px;
}

.graphs,
.header-text,
.cone-resistance,
.friction-ratio,
.soil-bar {
	border-radius: 2px;
}

.friction-ratio,
.soil-bar {
	margin-left: 30px;
}

.info {
	margin-left: 10px;
}

.header-text,
.cone-resistance,
.friction-ratio,
.soil-bar {
	background-color: #5f6060;
}
