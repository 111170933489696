@value colors: "~styles/colors.module.css";
@value mid-gray, colors-indigo-200, colors-blue-grey-200 from colors;

.side-bar {
	display: flex;
	flex-flow: column;
	position: absolute;
	top: calc(64px + 8px);
	max-height: calc(100% - 64px - 8px - 10px) !important;
	left: 8px;
	width: 352px;
	border-radius: 4px;
	background-color: mid-gray;
	box-sizing: border-box;
	font-family: Barlow, sans-serif;
	z-index: 1000;
	padding: 16px 16px 8px 16px;
	overflow: hidden;
	user-select: none;
}

.side-bar-pushed {
	top: calc(64px + 54px + 8px);
}

.search-bar-tree-dots-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.project {
	position: relative;
	margin: 8px 16px 16px 16px;
	font-size: 14px;
	line-height: 1.43;
}

.project-files {
	flex-grow: 1;
	overflow-y: scroll;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.project-files::-webkit-scrollbar {
	display: none;
}

.cpt-item {
	display: flex;
	justify-content: space-between;
	list-style-type: none;
	align-items: center;
	margin-bottom: 4px;
}

.cpt-number {
	font-family: Barlow, sans-serif;
	font-size: 16px;
	font-weight: 600;
	color: colors-indigo-200;
	margin: 0 0 8px;
	line-height: 1.75;
}

.project-name {
	font-size: 16px;
	font-weight: 600;
	color: colors-indigo-200;
	margin: 0;
	line-height: 1.75;
}

.project-link {
	cursor: pointer;
}

.cpt-count {
	font-size: 13px;
	padding: 2px 3px;
	color: black;
	background-color: colors-blue-grey-200;
	border-radius: 3px;
}

.cpt-date {
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: normal;
	color: #aaaaaa;
}

.divider {
	margin: 12px -16px;
}

.card-header {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	justify-content: space-between;
}