
.root {
	position: absolute;
	top: 63px;
	right: 0px;
	left: 0px;
	height: 24px;
	z-index: 1000;

	border-radius: unset;
	background-color: #ffd67c;
	font-family: Barlow;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #333333;

	padding: 15px 32px 15px 44px;
}

.icon {
	padding: unset;
	margin: unset;
	color: #333333 !important;
}

.message {
	padding: unset;
	margin-left: 30px;
	font-family: Barlow;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #333333;
}

.action {
	padding: unset;
}
