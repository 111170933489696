@value colors: '~styles/colors.module.css';
@value border-gray, white, colors-indigo-200, colors-1-primary, error-red from colors;

.root {
	background-color: transparent;
	color: white;
}

.root fieldset {
	border: 1px solid border-gray;
	border-radius: 3px;
}

.root:hover fieldset {
	border-color: #787778 !important;
}

.text-field label {
	transform: translate(14px, 13px) scale(1);
}

.input {
	align-items: center;
	padding: 10px 0 10px 16px;
	font-size: 16px;
}

.focused:hover fieldset,
.focused fieldset {
	border: 1px solid colors-1-primary !important;
}

.focused label {
	transform: translate(14px, -6px) scale(0.75) !important;
}

.error:hover fieldset,
.error fieldset {
	border: 1px solid error-red !important;
}

.label-root.label-focused {
	color: colors-indigo-200;
}
