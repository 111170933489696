@value colors: "~styles/colors.module.css";
@value colors-indigo-200, gray-50, colors-teal-500, colors-1-primary, white from colors;

.popup {
	top: -17px !important;
}

.cpt-icon {
	cursor: pointer;
}

:global(.mapboxgl-popup-tip) {
	display: none;
}

:global(.mapboxgl-popup-content) {
	padding: 8px 16px 14px !important;
	border-radius: 4px !important;
	background-color: #2e2f30 !important;
	display: flex;
	flex-direction: row;
}

:global(.mapboxgl-popup-content) > div {
	display: flex;
	flex-direction: column;
}

:global(.mapbox-improve-map) {
	display: none;
}

.name {
	font-family: Barlow, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	color: colors-indigo-200;
	text-transform: capitalize;
	margin-bottom: 5px;
}

.contractor-head,
.contractor,
.abw-reference,
.modified,
.owner,
.date {
	font-family: Barlow, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 16px;
}

.date,
.abw-reference {
	color: gray-50;
	margin-bottom: 16px;
}

.modified,
.owner {
	color: white;
}

.cluster {
	transition: 0s all;
	color: #333333;
	border-radius: 50%;
	cursor: pointer;
	width: 20px;
	height: 20px;
	padding: 3px;
	border: solid 2px rgba(169, 157, 255, 0.4);
	background-color: rgba(169, 157, 255, 0.7);
	background-clip: padding-box;
	text-align: center;
}

.cluster-selected {
	color: white;
	background-color: colors-1-primary;
	border-color: rgba(100, 75, 255, 0.8); /* colors-1-primary * 0.8 */
}

.cluster:hover {
	background-color: colors-teal-500;
	border-color: rgba(1, 206, 189, 0.7);
	transition-delay: 50ms;
}
