@value colors: "~styles/colors.module.css";
@value slightly-less-dark-gray, grid-gray, graph-line-color, colors-1-primary from colors;

.container {
	display: flex;
	flex-direction: column;
	margin-left: 23px;
}

.header-container {
	height: 17px;
}

.header {
	font-size: 14px;
	line-height: 1.43;
	color: slightly-less-dark-gray;
	white-space: nowrap;
}

.bar {
	position: relative;
	box-sizing: border-box;
	border: 1px solid grid-gray;
	list-style: none;
	padding: 0;
}

.borehole-z {
	width: 100%;
	position: absolute;
	background-color: graph-line-color;
	height: 1px;
	z-index: 999;
}
