.tab {
	letter-spacing: 0.5 !important;
}

.fields {
	display: flex;
	width: 100%;
	padding-top: 22px;
}

.column {
	flex: 1;
}

.column:nth-child(2) {
	margin-left: 24px;
}

.drop-down,
.text-field {
	margin-bottom: 17px;
}

.drop-down {
	height: auto;
}

.text-field {
	margin-bottom: 30px;
}

.text-field label {
	font-weight: normal;
}

.text-area > div {
	padding: 0;
}

.text-area textarea {
	padding-right: 11px;
	line-height: 24px;
	font-weight: normal;
}

.end-adornment {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: normal;
	text-align: right;
	color: rgb(170, 170, 170);
}

.error fieldset {
	border-color: #de3163 !important;
}

.error label,
.error p {
	color: #de3163 !important;
}

.error p {
	font-size: 12px;
}

.error p {
	position: absolute;
	bottom: -22px;
}

.my-projects {
	font-family: Barlow;
	margin: 24px -14px -16px;
	overflow-y: auto;
	max-height: 340px;
}

.my-project {
	color: #aaaaaa;
}
.my-project,
.my-project-selected {
	font-size: 14px;
	border-radius: 4px;
	padding-top: 12px;
	padding-left: 16px;
	padding-bottom: 16px;
}

.my-project-first-line {
	margin-bottom: 8px;
}

.my-project-title {
	font-size: 16px;
	color: #a99dff;
}

.my-project:hover {
	background-color: #424852;
	cursor: pointer;
}

.my-project-selected,
.my-project-selected:hover,
.my-project-selected .my-project-title {
	background-color: #644bff;
	color: #ffffff;
	cursor: pointer;
}

.sorted-by {
	float: right;
	margin-top: 9px;
	margin-right: -1px;
	width: 206px;
}

.field-container {
	display: flex;
}

.outlined-input {
	margin-left: 16px;
}

.info-icon {
	position: relative;
	top: 2px;
	width: 14px;
	height: 14px;
	cursor: pointer;
	margin-left: 3px;
}