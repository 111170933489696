@value colors: "~styles/colors.module.css";
@value color-greyscale-900, border-gray from colors;

.fields {
	display: flex;
	padding-top: 12px;
}

.end-adorment {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: normal;
	text-align: right;
	color: rgb(170, 170, 170);
}

.cohesion-text-field {
	margin-right: 12px;
}

.friction-angle-text-field {
	margin-left: 12px;
}
