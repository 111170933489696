@value colors: "~styles/colors.module.css";
@value typography: '~styles/typography.module.css';
@value white, dark-blue, dark-blue-900 from colors;
@value button-small from typography;

.export-interpretation {
	position: relative;
}

.export-interpretation-button {
	margin-left: 8px;
}

.button-text {
	margin-top: -2px;
}
