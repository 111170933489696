@value colors: "~styles/colors.module.css";
@value colors-1-primary, colors-blue-grey-900 from colors;

.project-files {
	flex-grow: 1;
	overflow-y: scroll;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
	margin-left: -8px;
	margin-right: -8px;
}

.project-files:focus {
	outline: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.project-files::-webkit-scrollbar {
	display: none;
}

.header {
	color: #aaaaaa;
	padding-left: 8px;
	font-family: Barlow, sans-serif;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 31px;
	letter-spacing: 1px;
	margin: 8px 0 0 0;
}

.cpt-list {
	padding-left: unset;
	display: flex;
	flex-direction: column;
	margin-top: 0;
	margin-block-end: 0 !important;
}

.cpt-item {
	display: flex;
	justify-content: space-between;
	list-style-type: none;
	align-items: center;
	border-radius: 1px;
	padding: 3px 8px;
	cursor: pointer;
}

.cpt-item:hover {
	background-color: colors-blue-grey-900;
}

.cpt-item-selected {
	background-color: colors-1-primary;
}

.cpt-item-selected:hover {
	background-color: colors-1-primary;
	color: white;
}

.cpt-item-selected .cpt-date {
	color: white;
}

.cpt-number {
	font-family: Barlow;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: normal;
}

.cpt-date {
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: normal;
	color: #aaaaaa;
}

.empty-text {
	box-sizing: border-box;
	margin-top: 2px;
	color: #aaaaaa;
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: normal;
	padding-left: 8px;
}

.warning-msg {
	margin-top: 4px;
	margin-bottom: 20px;
}
