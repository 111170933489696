@value colors: "~styles/colors.module.css";
@value gray, arrow-gray from colors;

.root {
	position: relative;
	border-top: 1px solid gray;
	padding: 16px 16px 0;
	margin-bottom: 1px;
}

.input-root {
	height: 40px;
}

.group-label {
	padding-left: 24px;
	text-transform: uppercase;
	color: white;
	font-family: Barlow, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 2.58;
	letter-spacing: 1px;
	margin-top: 0;
}

.input-root input {
	padding-right: 30px !important;
	padding-left: 30px !important;
	padding-top: 0 !important;
}

.select-end-adornment {
	top: initial;
}

.select-end-adornment button:hover {
	background-color: initial;
}

.icon {
	color: arrow-gray;
}

.soil-material {
	display: flex;
	align-items: center;
}

.label-color-box,
.color-box {
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 2px;
	margin-right: 8px;
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.label-color-box {
	position: absolute;
	left: 30px;
	top: 28px;
	z-index: 10;
}

.autocomplete-selected-text,
.autocomplete-text {
	font-size: 16px !important;
	line-height: 16px;
	letter-spacing: normal;
}

.option {
	padding-top: 6px;
	padding-bottom: 6px;
}

.option:last-child {
	margin-bottom: 10px;
}

.buttons {
	margin-top: 16px;
}

.remove-btn {
	height: 30px;
}

.split-btn {
	height: 30px;
	margin-left: 24px !important;
	font-weight: 600;
}

.option-image {
	width: 16px;
	height: 16px;
	border-radius: 2px;
}
