body,
html,
#root {
	margin: 0;
	padding: 0;
	font-size: 14px;
	height: 100%;
	font-family: Barlow, sans-serif !important;
	color: #ffffff;
	overflow: hidden;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}
