@value colors: "~styles/colors.module.css";
@value dark-gray, arrow-gray, colors-teal-500, colors-indigo-400, colors-blue-grey-700 from colors;

.root {
	background-color: dark-gray !important;
	box-sizing: border-box;
}

.top-bar {
	padding: 0;
}

.top-bar-left {
	display: flex;
	flex: 1;
	justify-content: space-between;
	padding-left: 40px;
}

.breadcrumb-container {
	display: flex;
	align-items: center;
}

.breadcrumb {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 32px;
	font-size: 12px;
	letter-spacing: 1px;
}

.breadcrumb a {
	text-decoration: none;
}

.breadcrumb a:link,
.breadcrumb a:visited {
	color: #ffffff;
}

.breadcrumb a:visited:hover {
	color: colors-teal-500;
}

.breadcrumb-last {
	color: #aaaaaa;
}

.breadcrumb span:not(.breadcrumb-last):hover {
	color: colors-teal-500;
	cursor: pointer;
}

.top-bar-right {
	display: flex;
	justify-content: flex-end;
	padding: 0 16px;
	width: 354px;
	box-sizing: border-box;
}

.form-control > div {
	width: 82px;
	height: 32px;
	background-color: #464749;
	border-radius: 2px;
}

.form-control > div > fieldset {
	border-width: 1px !important;
	border-style: solid !important;
	border-color: #575657;
}

.form-control > div:hover > fieldset {
	border-color: #787778 !important;
}

.chart-select-root {
	background-color: unset;
	padding: 8px 20px 8px 16px;
}

.chart-select-icon {
	margin-top: 2px;
	right: 10px;
	color: #ababab;
}

.chart-menu-paper {
	top: 52px !important;
	border-radius: 2px;
}

.chart-menu-item-selected {
	background-color: unset !important;
}

.chart-menu-item-root {
	height: 36px;
	padding-left: 10px;
}

.chart-menu-item-selected:hover,
.chart-menu-item-root:hover {
	background-color: colors-blue-grey-700 !important;
}

.chart-item-checkbox-root,
.chart-item-checkbox-checked {
	color: colors-indigo-400 !important;
	margin-right: 5px;
}

.zoom-handler {
	display: flex;
	align-items: center;
	margin-left: 40px;
}

.select-container {
	display: flex;
	align-items: center;
}

.autocomplete {
	height: 32px;
	background-color: #464749;
	border-radius: 3px;
}

.select {
	width: 85px;
	margin-left: 12px;
}

.inputRoot {
	height: 32px;
	padding-right: 30px !important;
}

.input {
	margin-top: -10px;
	padding-left: 0px;
	min-width: 40px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: normal;
}

.select-end-adornment {
	top: initial;
}

.select-end-adornment button:hover {
	background-color: initial;
}

.select-label {
	font-size: 13px;
}

.zoom-label {
	font-size: 13px;
	font-weight: 600;
}

.icon {
	color: arrow-gray;
}
