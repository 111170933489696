@value colors: "~styles/colors.module.css";
@value colors-indigo-200 from colors;

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.header-container {
	height: 17px;
	width: 100%;
}

.header {
	font-size: 14px;
	line-height: 1.43;
	color: #6358af;
	margin-left: 35px;
}

.purple-bar {
	position: absolute;
	z-index: 1;
	background-color: rgba(100, 75, 255, 0.23);
	pointer-events: none;
}
