@value colors: "~styles/colors.module.css";
@value colors-grey-400, slightly-less-dark-gray from colors;

.root {
	height: 24px;
	margin-left: 12px;
	background-color: colors-grey-400;
	color: slightly-less-dark-gray;
	border-radius: 3px;
	display: flex;
	align-items: center;
	padding: 0 6px;
	letter-spacing: .3px;
}
