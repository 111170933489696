@value colors: "~styles/colors.module.css";
@value dark-gray, white from colors;

.root {
	font-size: 16px;
}

.paper {
	background-color: #222222;
	color: white;
	max-width: 680px;
}

.title {
	padding: 16px 24px 20px;
}

.title h2 {
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 28px;
	letter-spacing: normal;
}

.dividers {
	border-top-color: #434343;
	border-bottom-color: #222222;
	padding-top: 4px;
	overflow: hidden;
}

.dialog-actions {
	padding: 16px;
}
