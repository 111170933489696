@value colors: "~styles/colors.module.css";
@value colors-blue-grey-700 from colors;

.list-item {
	padding: 8px 8px 8px 15px;
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 18px;
	letter-spacing: normal;
}

.list-item:hover {
	background-color: colors-blue-grey-700;
}

.active-selection-item-content {
	display: flex;
	align-items: center;
}