.box {
	display: inline-flex;
	flex-direction: column;
	padding: 8px 24px 23px 24px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 340px;
	height: 33px;
	border-radius: 3px;
	background-color: rgba(51, 51, 51, 0.8);
	z-index: 9999;
}

.title {
	font-family: Barlow;
	font-weight: 600;
	line-height: 1.5;
	color: white;
	margin-bottom: 8px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
}

.loaders {
	display: flex;
}

.progress-uploading,
.progress-processing {
	flex: 1;
	height: 4px;
	border-radius: 2px;
}

.progress-updating,
.progress-uploading {
	background-color: rgba(169, 157, 255, 0.5);
}

.progress-bar-updating,
.progress-bar-uploading {
	background-color: #a99dff;
}

.progress-processing {
	background-color: rgba(1, 206, 189, 0.5);
	margin-left: 12px;
}

.progress-bar-processing {
	background-color: #01cebd;
}
