
.project-list {
	padding-left: 8px;
	padding-right: 8px;
	margin-left: -8px;
	margin-right: -8px;
	overflow-y: scroll;
	overflow-x: hidden;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}

/* Scrollbar was still showing up for Google Chrome 88.0.4324.96 (Linux) */
.project-list::-webkit-scrollbar {
	display: none;
}

.header {
	color: #aaaaaa;
	font-family: Barlow, sans-serif;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 31px;
	letter-spacing: 1px;
	margin: 8px 0 0 0;
}

.empty-text {
	box-sizing: border-box;
	margin-top: 2px;
	color: #aaaaaa;
}