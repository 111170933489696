@value colors: "~styles/colors.module.css";
@value mid-gray, colors-indigo-200, colors-blue-grey-200, colors-blue-grey-800, white from colors;

.project {
	padding: 8px;
	margin: 0 -8px 8px;
	border-radius: 2px;
	cursor: pointer;
}

.project:last-child {
	margin-bottom: 0;
}

.project:hover {
	background-color: colors-blue-grey-800;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}

.link {
	cursor: pointer;
	font-family: Barlow;
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 28px;
	letter-spacing: normal;
	color: colors-indigo-200;
}

.soil-investigation-count {
	font-size: 13px;
	padding: 2px 3px;
	color: rgba(0, 0, 0, 0.87);
	background-color: colors-blue-grey-200;
	border-radius: 3px;
}

.abw-reference {
	color: #aaaaaa;
	display: block;
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: normal;
}

.description {
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: normal;
	color: white;
}
