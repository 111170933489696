@value colors: "~styles/colors.module.css";
@value colors-blue-700 from colors;

.popper {
	z-index: 9000;
}

.popup-menu {
	margin-top: 2px;
	padding-top: 10px;
}

.header {
	color: colors-blue-700;
	padding: 0 16px;
	font-family: Barlow;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
