@value colors: '~styles/colors.module.css';
@value colors-indigo-200 from colors;

.info-icon {
	position: relative;
	top: 2px;
	width: 14px;
	height: 14px;
	cursor: pointer;
	margin-left: 3px;
}

.tooltip {
	font-size: 10px;
}

.outlined-input {
	width: 100%;
}

.label {
	transform: translate(12px, 12px) scale(1);
}

.label-focused {
	color: colors-indigo-200 !important;
}

.error {
	color: #de3163 !important;
}
