@value colors: "~styles/colors.module.css";
@value colors-grey-700 from colors;

.skeleton {
	margin-top: 24px;
}

.item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 24px;
}

.header {
	margin-bottom: 8px;
	border-radius: 2px;
	background-color: colors-grey-700;
}

.content {
	border-radius: 2px;
	background-color: colors-grey-700
}
