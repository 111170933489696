@value colors: "~styles/colors.module.css";
@value mid-gray, white, colors-2-secondary, colors-blue-grey-800, colors-blue-grey-700 from colors;

.sidebar {
	background-color: mid-gray !important;
	width: 354px;
	box-sizing: border-box;
	height: calc(100% - 64px);
	padding: 0 0 24px 0;
	position: absolute;
	right: 0;
	transition: transform 300ms ease-in-out;
}

.closed-sidebar {
	transform: translateX(calc(100% - 24px));
}

.sidebar-container {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
}

.sidebar-container > div:last-child > div {
	height: 100%;
}

.sidebar-button {
	position: absolute;
	top: 2px;
	left: 3px;
	margin-top: 4px;
}

.empty-text {
	font-size: 20px;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: 0.07px;
	color: white;
	margin: 27px 6px 0 8px;
}

.tab-root {
	padding: 0 22px 0 24px;
}

.tab-root button {
	min-width: 150px;
}

.tab-root button span {
	font-family: Barlow;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: 0.5px;
	text-align: center;
}

.tab-container {
	padding-bottom: 1px;
	height: 100%;
}

.tab-indicator {
	background-color: colors-2-secondary;
}

.tab-panel {
	height: 100%;
}

:global(.react-swipeable-view-container) > div {
	overflow: hidden !important;
}
