@value colors: "~styles/colors.module.css";
@value gray from colors;

.root {
	position: relative;
	display: flex;
	background-color: gray !important;
	height: calc(100% - 64px);
	width: calc(100% - 354px);
	transition: width 300ms ease-in-out;
}

.closed-side-bar {
	width: calc(100% - 24px);
	transition: width 0ms ease-in-out;
}

.scroll-container {
	overflow: auto;
	display: flex;
	cursor: grab;
	flex: 1;
}

.scroll-container::-webkit-scrollbar {
	display: none;
}

.scroll-container-drag {
	user-select: none;
	cursor: grabbing;
}

.content {
	display: flex;
	flex: 1;
	height: 100%;
}

.wrapper {
	position: relative;
	display: flex;
	transform-origin: 0 0 0;
	flex: 1;
	padding-right: 150px;
}

:global(.react-transform-component) {
	width: 100%;
	height: 100%;
}
