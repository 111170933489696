
.borehole-sidebar  {
  padding: 30px 25px;
  height: 100% !important;
}

.ground-waterlevel {
  height: auto !important;
  margin-bottom: 20px;
}

.divider {
  margin-bottom: 10px;
}

.soil-list {
  margin-left: -25px;
  margin-right: -25px;
}

.soil-item {
  border-top: 1px solid #ccc;
}

.soil-item:last-of-type {
  border-bottom: 1px solid #ccc;
}