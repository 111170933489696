@value colors: "~styles/colors.module.css";
@value slightly-less-dark-gray from colors;

.tooltip {
	width: 68px;
	transform: scale(1) !important;
	background-color: #01cebd;
	border-radius: 2px;
	padding: 4px 8px 6px 8px;
	box-sizing: border-box;
	margin: 0;
	display: flex;
	flex-direction: column;
}

.tooltip-label {
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.3px;
	color: slightly-less-dark-gray;
	margin: unset;
}

.tooltip-label-last {
	composes: tooltip-label;
	margin-top: -5px;
}

.tooltip-label-bold {
	composes: tooltip-label;
	font-weight: 600;
}
