@value colors: "~styles/colors.module.css";
@value mid-gray, white, colors-2-secondary, colors-blue-grey-800, colors-blue-grey-700 from colors;

.tab-root {
	padding: 0 22px 0 24px;
}

.tab-root button {
	min-width: 150px;
}

.tab-root button span {
	font-family: Barlow;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: 0.5px;
	text-align: center;
}

.tab-container {
	padding-bottom: 1px;
	height: 100%;
}

.tab-indicator {
	background-color: colors-2-secondary;
}

.tab-panel {
	height: 100%;
}

:global(.react-swipeable-view-container) > div {
	overflow: hidden !important;
}
