@value colors: "~styles/colors.module.css";
@value colors-blue-grey-700 from colors;

.menu {
	margin-left: 2px;
}

.list {
	width: 140px;
	padding: 4px 0;
}

.list-item {
	padding: 8px 0 8px 15px;
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 20px;
	letter-spacing: normal;
}

.list-item:hover {
	background-color: colors-blue-grey-700;
}
