.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 26px;
}

.textfields {
	margin-bottom: 8px;
}

.text-field {
	margin-bottom: 30px;
}

.text-field:last-child {
	margin-bottom: 0;
}

.error fieldset {
	border-color: #de3163 !important;
}

.error label,
.error p {
	color: #de3163 !important;
}

.error p {
	font-size: 12px;
}

.error p {
	position: absolute;
	bottom: -22px;
}

.end-adornment {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: normal;
	text-align: right;
	color: rgb(170, 170, 170);
}

.text-area > div {
	padding: 0;
}

.text-area textarea {
	padding-right: 11px;
	line-height: 24px;
	font-weight: normal;
}

.interpretation-icon {
	color: #ababab;
}

.field-container {
	display: flex;
}

.field-container > div {
	margin-bottom: 0;
}

.outlined-input {
	margin-left: 16px;
}
