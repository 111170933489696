@value colors: "~styles/colors.module.css";
@value dark-gray, colors-2-secondary, white from colors;

.container {
	position: relative;
}

.header-container {
	height: 17px;
}

.header {
	font-size: 14px;
	letter-spacing: 0.1px;
	color: #ab0f6e;
	margin-left: 45px;
}

.depth-tooltip {
	fill: colors-2-secondary;
}

.depth-tooltip-text {
	fill: white;
	font-size: 12px;
}

.purple-bar {
	position: absolute;
	z-index: 1;
	background-color: rgba(100, 75, 255, 0.23);
	pointer-events: none;
}
