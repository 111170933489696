.black-link a:link {
	color: #333333;
}

.black-link a:visited {
	color: #333333;
}

.white-link a:link a:visited {
	color: white;
}

.white-link a,
.white-link a:visited {
	color: white;
}

.bold {
	font-weight: 600;
}

.text {
	margin-bottom: 10px;
}

.item-text {
	padding-left: 8px;
	font-family: Barlow;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
}

.upload {
	display: flex;
	justify-content: center;
	align-items: center;
}

.upload > span {
	margin-top: 2px;
}

.info-button {
	margin-left: 30px !important;
	background-color: gary !important;
}

.profile-button {
	margin-left: 10px !important;
}

.icon {
	width: 24px;
	height: 24px;
}
