@value colors: "~styles/colors.module.css";
@value typography: '~styles/typography.module.css';
@value white, dark-blue, dark-blue-900 from colors;
@value button-small from typography;

.file-upload {
	position: relative;
}

.file-upload-input {
	display: none;
}

.file-upload-input:focus + .file-upload-button {
	outline: #644bff 1px solid;
	border-radius: 4px;
}

.button-text {
	margin-top: -2px;
}
