@value colors: "~styles/colors.module.css";
@value gray-200 from colors;

.root {
	margin: 0 30px 30px 30px;
}

.header {
	display: flex;
	align-items: center;
	font-size: 8.3px;
	margin: 7px 0 4px;
  height: 24px;
}

.soil-investigation {
	display: flex;
	background-color: gray-200;
	padding: 15px 35px 22px 0;
	box-sizing: border-box;
	justify-content: center;
}

.icon {
	margin-left: 10px;
	width: 12px;
	height: 12px;
	cursor: pointer;
}

.soil-layers {
	z-index: 250;
}

.water-pressure-title {
	color: #6358af;
}

.cone-resistance-title {
	color: #ab0f6e;
}

.friction-ratio-title,
.soilbar-title {
	color: #333333;
}
