
.box {
	display: inline-flex;
	flex-direction: column;
	margin: unset;
	padding: unset;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 600px;
	height: 233px;
	border-radius: 4px;
	box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
	background-color: #222222;
}

.title {
	height: 32px;
	margin: unset;
	padding: 15px 0px 15px 24px;
	font-family: Barlow;
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: normal;
	color: white;
	border-bottom: 1px solid #434343;
}

.content {
	width: 549px;
	height: 28px;
	margin: unset;
	padding: 16px 24px 14px 24px;
	font-family: Barlow;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: white;
}

.main {
	margin: unset;
	margin-bottom: 4px;
	font-family: Barlow;
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: normal;
	color: white;
}

.footer {
	width: 552px;
	height: 28px;
	padding: 0px 24px 14px 24px;
	position: absolute;
	bottom: 0px;
	display: flex;
	justify-content: flex-end;
}
