@value colors: "~styles/colors.module.css";
@value color-greyscale-900, border-gray from colors;

.fields {
	display: flex;
	padding-top: 12px;
}

.text-field {
	margin-bottom: 30px;
}

.cohesionTextField,
.frictionAngleTextField {
	margin-bottom: 16px !important;
}

.column {
	width: 50%;
}

.column:first-child {
	margin-right: 12px;
}

.column:last-child {
	margin-left: 12px;
}

.delete-soil-button {
	position: absolute;
	top: 16px;
	right: 24px;
	font-family: Barlow;
	font-size: 12px;
 	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: 0.3px;
	color: #ffffff;
	text-transform: initial;
}

.button-text-library {
	text-transform: capitalize;
}

.end-adornment {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 24px;
	letter-spacing: normal;
	text-align: right;
	color: rgb(170, 170, 170);
}

.category-arrow-icon {
	color: #ababab;
}

.error:hover fieldset,
.error fieldset {
	border: 1px solid #de3163 !important;
}

.error label,
.error p {
	color: #de3163 !important;
}

.error p {
	font-size: 12px;
	position: absolute;
	bottom: -22px;
}

.color-picker-wrapper {
	display: flex;
	flex-direction: column;
}

.color-picker-header {
	font-family: Barlow;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.58;
	letter-spacing: 1px;
	color: #aaaaaa;
	margin: unset;
	margin-bottom: 10px;
}

.colorPickerBoxes {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}

.color-picker-hex-box {
	width: 80px;
	height: 40px;
	border-radius: 4px;
	margin-right: 16px;
}

.color-picker-hex-box fieldset {
	border: solid 1px #575657;
	font-family: Barlow;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #aaaaaa;
}

.color-picker-hex-box input {
	padding: 11px 5px 10px 15px;
}

.color-picker-hex-box p {
	width: 200px;
}

.color-picker-color-box {
	width: 40px;
	height: 24px;
	border-radius: 3px;
	border: solid 1px rgba(44, 20, 20, 0.3);
	cursor: pointer;
}

.color-picker {
	position: absolute;
	left: 172px;
	bottom: -80px;
	background-color: color-greyscale-900 !important;
	border: solid 1px border-gray !important;
	box-shadow: none;
	color: #ffffff;
}

.color-picker:before,
.color-picker:after {
	content: '';
	display: block;
	position: absolute;
	left: -6px;
	top: 26px;
	width: 0;
	height: 0;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
}

.color-picker:before {
	border-right: 6px solid color-greyscale-900;
	z-index: 100;
}

.color-picker:after {
	border-right: 6px solid white;
	z-index: 99;
}

.color-picker input {
	box-shadow: inset 0 -1px 1px 0 rgba(204, 204, 204, 0.31) !important;
	background-color: rgba(255, 255, 255, 0.04);
	color: #ffffff;
	text-align: center;
	border-radius: 0;
	pointer-events: none;
}

.color-picker label {
	color: #ffffff !important;
	font-family: Barlow;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
}

.apply-button {
	margin-left: 16px !important;
}

:global(.flexbox-fix:nth-child(even)) {
	display: none !important;
}
